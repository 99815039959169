import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, toRaw, computed, toRefs, onMounted, ref } from 'vue';
import { selectHySalaryByParam, selectCheckAndLateRule } from "/src/api/attendance";
import useTablePagination from '@/hooks/pagination';
import moment from 'moment';
import Cookies from "js-cookie";
const columns = [{
  title: '月份',
  dataIndex: 'date'
}, {
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '身份证号',
  dataIndex: 'IDnum'
}, {
  title: '岗位',
  dataIndex: 'jobName',
  slots: {
    customRender: 'jobName'
  }
}, {
  title: '工资类型',
  dataIndex: 'salaryType',
  slots: {
    customRender: 'salaryType'
  }
}, {
  title: '日(月）工资(元)',
  dataIndex: 'wage'
}, {
  title: '出勤天数',
  dataIndex: 'attendanceDays'
}, {
  title: '旷工天数',
  dataIndex: 'absenteeismDay'
}, // {
//   title: '工资金额',
//   dataIndex: 'wageTrue',
// },
{
  title: '计加班费加班时长(小时)',
  dataIndex: 'overtime'
}, {
  title: '加班费(元)',
  dataIndex: 'overMoney'
}, {
  title: '请假总时长(天)',
  dataIndex: 'totalTimeDay'
}, {
  title: '考勤扣款(元)',
  dataIndex: 'attendanceDeductions'
}, {
  title: '请假扣款(元)',
  dataIndex: 'leaveMoney'
}, {
  title: '其他(元)',
  dataIndex: 'other'
}, {
  title: '总合计(元)',
  dataIndex: 'total'
}];
export default defineComponent({
  setup() {
    onMounted(() => {
      dateMonth.value = defaultSelectDate.monthDate;
      selectHySalaryByParam1();
    });
    let activeKey = ref('1');
    const loading = ref(false);
    let tableData = ref([]); ///////////////

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;

      if (activeKey.value == '1') {
        selectHySalaryByParam1();
      } else if (activeKey.value == '2') {
        selectHySalaryByParam1();
      }
    }; //设置默认日期


    const defaultSelectDate = {
      dayDate: moment().startOf('day').subtract(1, 'days'),
      monthDate: moment().startOf('month').subtract(0, 'days')
    };
    const dateMonth = ref(); //时间戳转化日期

    const format = shijianchuo => {
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    };

    const add0 = m => {
      return m < 10 ? '0' + m : m;
    }; //选择日期


    const changeMonth = () => {
      current.value = 1;
      pageSize.value = 10;
      selectHySalaryByParam1();
    }; //切换标签页


    const changeTabs = () => {
      current.value = 1;
      pageSize.value = 10;
      total.value = 0;
      dateMonth.value = defaultSelectDate.monthDate;

      if (activeKey.value == '1') {
        selectHySalaryByParam1();
      } else if (activeKey.value == '2') {
        tableData.value = [];
        Object.assign(formState, {
          name: '',
          IDNumber: ''
        });
      }
    };

    const formState = reactive({
      name: '',
      IDNumber: ''
    });

    const onSubmit = () => {
      if ((formState.IDNumber == '' || formState.IDNumber == null) && (formState.name == '' || formState.name == null)) {
        _message.error('请输入姓名或身份证号');

        return;
      }

      selectHySalaryByParam1();
    }; //获取工资表数据


    const selectHySalaryByParam1 = async () => {
      let param = {};

      if (activeKey.value == '2') {
        param = {
          userName: formState.name,
          idCard: formState.IDNumber,
          pageNumber: current.value ? current.value : 1,
          limit: pageSize.value ? pageSize.value : 10
        };
      } else {
        param = {
          createTime: format(dateMonth.value).slice(0, 7),
          pageNumber: current.value ? current.value : 1,
          limit: pageSize.value ? pageSize.value : 10
        };
      }

      loading.value = true;
      let res = await selectHySalaryByParam(param);

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              date: item.month,
              name: item.userName,
              IDnum: item.idCard,
              attendanceDays: item.workDays,
              wage: item.salaryDaysOrMonth,
              wageTrue: item.salaryTotal,
              overtime: item.overHours,
              totalTimeDay: item.totalTimeDay,
              overMoney: item.overHoursMoney,
              attendanceDeductions: item.loseMoney,
              other: item.punishmentOrAward,
              total: item.totalMoney,
              userNo: item.userNo,
              salaryType: item.salaryType,
              absenteeismDay: item.absenteeismDay,
              leaveMoney: item.leaveMoney,
              jobName: item.jobName
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    };

    const ruleVisible = ref(false);
    const ruleDate = reactive({
      knLateRules: [],
      absenceType: null,
      overInterval: null,
      minDuration: null,
      durationType: null,
      overtimePay: null,
      isRestDayOvertime: null
    });

    const handleRule = async () => {
      let param = {
        writeTime: format(dateMonth.value).slice(0, 7),
        belongProject: Number(Cookies.get('belongProject'))
      };
      let res = await selectCheckAndLateRule(param);

      if (res.code === 200) {
        ruleVisible.value = true;
        Object.assign(ruleDate, res.data);

        switch (ruleDate.absenceType) {
          case 1:
            ruleDate.absenceType = '二分之一日薪/缺卡时段';
            return;

          case 2:
            ruleDate.absenceType = '三分之一日薪/缺卡时段';
            return;

          case 3:
            ruleDate.absenceType = '按工作时长比例扣款';
            return;
        }
      } else {
        _message.error(res.message);
      }
    };

    return {
      tableData,
      columns,
      formState,
      onSubmit,
      activeKey,
      changeTabs,
      dateMonth,
      changeMonth,
      paginationConfig,
      onPageChange,
      loading,
      handleRule,
      ruleVisible,
      ruleDate
    };
  }

});